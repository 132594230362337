@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%
}

body, #root {
  height: 100vh;
}
